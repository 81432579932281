
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component<InfoBanner>({})

export default class InfoBanner extends Vue {
	@Prop({ type: String, required: true, default: '' }) private message!: string;
	@Prop({ type: String, required: false, default: 'Info' }) private iconName!: string;

	private icon: any = null;

	private created() {
		this.loadIcon();
	}

	/**
	 * Load the icon component dynamically.
	 * If the component is not found, default to InfoIcon.
	 * ! Currently only works with the vue-feather-icons package.
	 *
	 * @return {Promise<void>}
	 */
	private async loadIcon(): Promise<void> {
		try {
			const icon = await import(`vue-feather-icons/icons/${this.iconName}Icon`);
			this.icon = icon.default;
		}
		catch (error) {
			const defaultIcon = await import('vue-feather-icons/icons/InfoIcon');
			this.icon = defaultIcon.default;
		}
	}
}
